import React, { Component } from 'react'
import { Link } from "gatsby"
import '../css/about.css'
import Header from "../components/Header"
import Footer from "../components/Footer"
import Fade from 'react-reveal/Fade';

class About extends Component {
  render() {
    return (
      <div>
        <Header />
        <Fade>
        <div className="AboutContainer">
        
          <div className="FlexContainer">
            
              <div className ="AboutPhoto">
                <img src={require('../images/hi.jpg')}/>
                <div className = "caption">Photo by <a className = "rosa" href = "https://twitter.com/rosaguac">Rosa</a></div> 
              </div>

              <div className="AboutDescription">
                <h3>Hey! I’m Jemma.</h3>
                <p>
                  I’m a self-taught product designer and recent <a href="https://www.berkeley.edu">UC Berkeley</a>  grad. At Cal, I studied Psychology and was deeply embedded in the design community. I had the chance to participate in a number of organizations dedicated to making design on-campus more accessible.
                  I am lucky enough to have been involved in <a href="https://berkeleytime.com">Berkeleytime</a>, <a href="https://calblueprint.org">Cal Blueprint</a>, the <a href="https://wdd.io">Web Design DeCal</a>, <a href="https://www.berkeleyinnovation.org">Berkeley Innovation</a>, <a href="https://innovativedesign.club">Innovative Design</a>, and <a href="https://www.ethicalapparel.org">EthiCAL Apparel</a>, wearing different hats in each org.
                </p>
                  
                <h3>What I've been up to.</h3>
                <p>
                  I just wrapped up an internship at <a href = "https://www.figma.com/home">Figma</a>, a startup focused on building a collaborative interface tool, and am now designing at <a href="https://facebook.design">Facebook</a> full-time. Aside from work, I like making cold brew coffee, reading, taking care of my kitten, and attempting some form of daily exercise.
                </p>
              
                <h3> I wouldn’t be here without the help and support of others.</h3>
                <p>
                  Thank you to <a href="https://www.jsliu.work">Jess Liu</a> for believing in my ability and all your advice when I was first starting out (and still today). Thank you to <a href="https://ericls.com">Eric Liang</a>  and <a href="https://www.designbyroka.com">Shawn Park</a> for building the foundations of an incredible, student-led design course at Berkeley, which deeply impacted my decision to pursue design.
                </p>
              </div>

              
          </div>

          <div className="BackContainer">
              <Link className="BackHome" to="/">Back Home</Link>
          </div>
        </div>
        <Footer />
        </Fade>
      </div>
    )
  }
}

export default About;